import React, { useRef, useState, useCallback, useEffect } from "react";

import * as styles from "./infiniteLoop.module.css";

const InfiniteLoop = ({ speed = 1, direction = "right", children }) => {
  const [looperInstances, setLooperInstances] = useState(1);
  const outerRef = useRef();
  const innerRef = useRef();

  const setupInstances = useCallback(() => {
    if (!innerRef?.current || !outerRef?.current) return;

    const { width } = innerRef.current.getBoundingClientRect();

    const { width: parentWidth } = outerRef.current.getBoundingClientRect();

    const instanceWidth = width / innerRef.current.children.length;

    if (width < parentWidth + instanceWidth && Math.ceil(parentWidth / width) < Infinity) {
      setLooperInstances(looperInstances + Math.ceil(parentWidth / width));
    }
  }, [looperInstances, innerRef, outerRef]);

  useEffect(() => {
    setupInstances();
  }, [setupInstances]);

  return (
    <div className={styles.looper} ref={outerRef}>
      <div className={styles.looperInnerList} ref={innerRef}>
        {[...Array(looperInstances)].map((_, ind) => (
          <div
            key={ind}
            className={styles.looperListInstance}
            style={{
              animationDuration: `${speed}s`,
              animationDirection: direction === "right" ? "reverse" : "normal",
            }}
          >
            {children}
          </div>
        ))}
      </div>
    </div>
  );
};

export default InfiniteLoop;
