import React, { useState } from "react";

import { cn } from "../../lib/helpers";

import * as styles from "./FaqItem.module.css";
import * as commonStyles from "../../styles/common.module.css";

const FaqItem = ({ question, answer }) => {
  const [active, setActive] = useState(false);

  return (
    <>
      <button
        className={cn(styles.accordion, active && styles.active)}
        onClick={() => setActive(!active)}
      >
        <span
          className={cn(
            active ? commonStyles.colorAccent : commonStyles.colorDarkGray,
            commonStyles.fontButlerMedium
          )}
        >
          {question}
        </span>
      </button>
      <div
        className={cn(
          styles.panel,
          commonStyles.colorDarkGray,
          commonStyles.fontButler,
          active && styles.active
        )}
      >
        {answer}
      </div>
    </>
  );
};

export default FaqItem;
