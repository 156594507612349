import React from "react";
import { Link } from "gatsby";
import { cn } from "../lib/helpers";
import PortableText from "./portableText";

import slugify from "../slugify";
import * as styles from "./procedures.module.css";
import * as commonStyles from "../styles/common.module.css";
import FadeInSection from "./FadeIn";

function Procedures({ procedures = [] }) {
  return (
    <div className={styles.container}>
      {procedures.map(({ procedure }, indexP) => (
        <div key={`procedure_${indexP}`} className={styles.procedure}>
          <FadeInSection>
            <div className={styles.procedureHeader}>
              <h2
                className={cn(
                  styles.title,
                  commonStyles.colorAccent,
                  commonStyles.fontButlerMedium
                )}
              >
                {procedure.title}
              </h2>
              <h2
                className={cn(
                  styles.title,
                  styles.priceTitle,
                  commonStyles.colorAccent,
                  commonStyles.fontButlerMedium
                )}
              >
                {procedure.price}
              </h2>
            </div>
            <div className={cn(styles.body, commonStyles.colorDarkGray, commonStyles.fontButler)}>
              <PortableText blocks={procedure._rawBody} />
            </div>
            <div className={cn(styles.tags, commonStyles.fontReleway, commonStyles.colorDarkGray)}>
              {procedure.categories.map((tag, indexT) => {
                const { title } = tag;
                return (
                  <Link to={`/vyhledavani/${slugify(title)}`}>
                    <span key={`procedure_tag_${indexT}`}>
                      {title}
                      {indexT !== procedure.categories.length - 1 && " — "}
                    </span>
                  </Link>
                );
              })}
            </div>
          </FadeInSection>
        </div>
      ))}
    </div>
  );
}

export default Procedures;
