// extracted by mini-css-extract-plugin
export var mainContainer = "extendedTCM-module--mainContainer--3t6cn";
export var container = "extendedTCM-module--container--1Mshx";
export var text = "extendedTCM-module--text--2j5V5";
export var image = "extendedTCM-module--image--3bgQ7";
export var body = "extendedTCM-module--body--16_cv";
export var title = "extendedTCM-module--title--3iF2j";
export var pricelist = "extendedTCM-module--pricelist--2mj9N";
export var priceTitle = "extendedTCM-module--priceTitle--ndRrV";
export var price = "extendedTCM-module--price--qopZn";
export var logo = "extendedTCM-module--logo--3kxGl";
export var gallery = "extendedTCM-module--gallery--1Zmeo";
export var icon = "extendedTCM-module--icon--1LPrU";
export var rotating = "extendedTCM-module--rotating--3vg88";
export var services = "extendedTCM-module--services--1LRY8";
export var reverse = "extendedTCM-module--reverse--1S7ja";
export var noImage = "extendedTCM-module--noImage--2_FO3";
export var noImageReverse = "extendedTCM-module--noImageReverse--3LORq";
export var container1 = "extendedTCM-module--container1--1SWhp";
export var overflowHidden = "extendedTCM-module--overflowHidden--3bP1P";
export var maxWidth1 = "extendedTCM-module--maxWidth1--glKrO";
export var maxWidth2 = "extendedTCM-module--maxWidth2--H5IYO";
export var procedureTitle = "extendedTCM-module--procedureTitle--1yc7b";
export var priceContainer = "extendedTCM-module--priceContainer--2wP3C";
export var mainTitle = "extendedTCM-module--mainTitle--Ij83I";
export var indication = "extendedTCM-module--indication--_YTk8";
export var indicationTitle = "extendedTCM-module--indicationTitle--Kz5wf";
export var galleryImage = "extendedTCM-module--galleryImage--39p3g";